"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_react_1 = require("@iress/components-react");
/* eslint-disable react-perf/jsx-no-new-object-as-prop  -- for the IDS IressCol options */
const Alert = ({ message, status }) => {
    return ((0, jsx_runtime_1.jsx)(components_react_1.IressRow, { horizontalAlign: components_react_1.IressRow.HorizontalAlign.Center, children: (0, jsx_runtime_1.jsx)(components_react_1.IressCol, { span: {
                lg: components_react_1.IressCol.Span.Ten,
                md: components_react_1.IressCol.Span.Twelve,
            }, children: (0, jsx_runtime_1.jsx)(components_react_1.IressAlert, { status: status, children: message }) }) }));
};
exports.Alert = Alert;
