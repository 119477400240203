"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TvShowResults = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_react_1 = require("@iress/components-react");
const Alert_1 = require("./Alert");
const PageSkeleton_1 = require("./PageSkeleton");
const messages_1 = require("./errors/messages");
const TvShowResults = ({ data, isLoading, hasError }) => {
    if (hasError) {
        return (0, jsx_runtime_1.jsx)(Alert_1.Alert, { message: messages_1.API_SEARCH_RESULT_ERROR, status: components_react_1.IressAlert.Status.Error });
    }
    if (isLoading) {
        return (0, jsx_runtime_1.jsx)(PageSkeleton_1.PageSkeleton, { count: 3 });
    }
    if (data.length > 0) {
        return ((0, jsx_runtime_1.jsx)(components_react_1.IressInline, { gutter: components_react_1.IressInline.Gutter.Lg, horizontalAlign: components_react_1.IressInline.HorizontalAlign.Center, children: data.map((tvShow) => ((0, jsx_runtime_1.jsxs)(components_react_1.IressCard, { "data-testid": "tv-show-item", headingLevel: components_react_1.IressCard.HeadingLevel.H4, headingText: tvShow.name, children: [(0, jsx_runtime_1.jsx)("img", { alt: tvShow.name, slot: components_react_1.IressCard.Slot.Supplemental, src: tvShow.image }), (0, jsx_runtime_1.jsx)(components_react_1.IressIcon, { "data-testid": "tv-show-rating-icon", name: "star", screenreaderText: "rating" }), (0, jsx_runtime_1.jsx)("span", { className: "tv-show-rating-icon", children: tvShow.rating })] }, tvShow.name))) }));
    }
    return (0, jsx_runtime_1.jsx)(Alert_1.Alert, { message: messages_1.NO_RESULT_FOUND, status: components_react_1.IressAlert.Status.Info });
};
exports.TvShowResults = TvShowResults;
