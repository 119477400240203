"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const client_1 = require("react-dom/client");
// eslint-disable-next-line node/no-process-env -- need to use process.env
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax -- need to ignore this
    void import('./mocks/browser').then((_a) => __awaiter(void 0, [_a], void 0, function* ({ worker }) { return worker.start(); }));
}
const App_1 = require("./App");
const container = document.querySelector("#app");
if (container !== null) {
    const root = (0, client_1.createRoot)(container);
    root.render((0, jsx_runtime_1.jsx)(App_1.App, {}));
}
